// eslint-disable-next-line
var freeText = function freeText(maxLength) {
  return "^[0-9a-zA-Z\xC0-\u017E.,;:!?/_\u2013@'%*()+#& ^=-]{1,".concat(maxLength, "}$");
};
export var FormComponents;

/**
 * Validations should be kept up-to-date with backend validations
 */
(function (FormComponents) {
  FormComponents[FormComponents["Dropdown"] = 0] = "Dropdown";
  FormComponents[FormComponents["Input"] = 1] = "Input";
  FormComponents[FormComponents["AutoComplete"] = 2] = "AutoComplete";
})(FormComponents || (FormComponents = {}));
export var OnboardingValidation = {
  OFFICIAL_NAME_PATTERN: freeText(35),
  OFFICIAL_NAME_MAX_LENGTH: 35,
  // eslint-disable-next-line
  BUSINESS_ID_PATTERN: '^[\\d]{7}-[\\d]$',
  WEBSITE_URL_PATTERN: '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  STREET_ADDRESS_PATTERN: freeText(35),
  STREET_ADDRESS_MAX_LENGTH: 35,
  CITY_PATTERN: freeText(50),
  CITY_MAX_LENGTH: 50,
  FIRST_NAME_PATTERN: freeText(14),
  FIRST_NAME_MAX_LENGTH: 14,
  LAST_NAME_PATTERN: freeText(20),
  LAST_NAME_MAX_LENGTH: 20,
  EMAIL_PATTERN:
  // eslint-disable-next-line
  "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\\.[a-zA-Z]{2,24})$",
  EMAIL_MAX_LENGTH: 255,
  // eslint-disable-next-line
  PHONE_NUMBER_PATTERN: '^[+\\s]*[\\(]{0,1}[0-9]{1,4}[\\)]{0,1}[\\s\\.\\/0-9\\-]*$',
  PHONE_NUMBER_MAX_LENGTH: 25
};