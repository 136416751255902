export default {
  fatalError: {
    title: 'Yhteys palvelimelle epäonnistui.',
    body: 'Lähettämisen palvelua ei voitu ladata.',
    reload: 'Yritä uudelleen'
  },
  verifyInformationView: {
    notificationText: 'Olet rekisteröitymässä Postin yritysasiakkaaksi. Tarkista, että alla olevat tiedot ovat oikein.',
    buttonBack: 'Takaisin',
    accountAlreadyExists: 'Samalla sähköpostiosoitteella on jo Postin asiakastili luotuna, joten sinun tulee aktivoida yritysasiakkaan käyttöoikeudet klikkaamalla vahvistusviestissä olevaa linkkiä ja kirjautumalla palveluun olemassa olevalla käyttäjätililläsi.'
  },
  becomeCustomerView: {
    hasWebStore: 'Verkkokauppa?',
    notificationStart: 'Pyydämme lomakkeella vain niitä tietoja, joita tarvitsemme asian käsittelemiseksi. Lisää tietoa ',
    notificationLink: 'henkilötietojen käsittelystä',
    notificationEnd: ' Postissa.',
    companyInformationHeader: 'Yrityksen tiedot',
    registrationInfoTooltip: 'Pyydämme lomakkeella vain niitä tietoja, joita tarvitsemme asian käsittelemiseksi. Lisää tietoa henkilötietojen käsittelystä Postissa.',
    companyName: 'Yrityksen nimi',
    businessId: 'Y-tunnus',
    websiteUrl: 'Mahdollisen verkkokaupan URL-osoite',
    country: 'Maa',
    officialCompanyAddress: 'Osoite',
    addressTooltip: 'Jos yrityksesi osoitteessa on enemmän kuin yksi rivi, erota rivit pilkulla.',
    postalCode: 'Postinumero',
    city: 'Postitoimipaikka',
    tradeRegister: 'Yritys on rekisteröity',
    tradeRegisterLink: 'Suomen kaupparekisteriin',
    mandatoryInfoText: '*Pakollinen tieto',
    addressHeadline: 'Yrityksen osoite',
    addressSubtitle: 'Tätä osoitetta käytetään yrityksen vierailu-, toimitus- ja laskutusosoitteena. Voit muuttaa tietoja myöhemmin.',
    mainUserInformationHeader: 'Pääkäyttäjän tiedot',
    mainUserInformation: 'Antamalla sähköpostiosoitteen, jolla on jo Postin Verkkopalvelussa tili, lisätään samaan tiliin tämä uusi yritystili.',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    emailAddress: 'Sähköpostiosoite',
    phone: 'Puhelinnumero',
    creditCheckInfo: 'Vahvistan täällä, että:',
    creditCheckFirst: 'minulla on oikeus toimia rekisteröimäni yrityksen pääkäyttäjänä Postin sopimusasiakkaiden ',
    // eslint-disable-next-line
    creditCheckLink: 'sähköisten kanavien käyttöehtojen',
    creditCheckEnd: ' edellyttämällä tavalla.',
    creditCheckSecond: 'rekisteröimälleni yritykselle voidaan tehdä luottotietojen tarkistus.',
    buttonNext: 'Jatka',
    buttonCancel: 'Peruuta'
  },
  errors: {
    officialNameField: {
      valueMissing: 'Anna yrityksen nimi',
      patternMismatch: 'Nimi on virheellinen'
    },
    businessIdField: {
      valueMissing: 'Anna yrityksen Y-tunnus',
      patternMismatch: 'Virhe Y-tunnuksessa. Anna suomalainen Y-tunnus muodossa 1234567-8.',
      badInput: 'Y-tunnusta ei löydy. Anna voimassa oleva Y-tunnus.'
    },
    addressField: {
      valueMissing: 'Anna yrityksen osoite',
      patternMismatch: 'Osoite on virheellinen'
    },
    postalCodeField: {
      valueMissing: 'Anna postinumero',
      patternMismatch: 'Postinumero on virheellinen'
    },
    cityField: {
      valueMissing: 'Anna postitoimipaikka',
      patternMismatch: 'Postitoimipaikka on virheellinen'
    },
    firstName: {
      valueMissing: 'Anna etunimi',
      patternMismatch: 'Etunimi on virheellinen'
    },
    lastName: {
      valueMissing: 'Anna sukunimi',
      patternMismatch: 'Sukunimi on virheellinen'
    },
    email: {
      valueMissing: 'Anna sähköpostiosoite',
      patternMismatch: 'Virhe sähköpostiosoitteessa. Anna sähköpostiosoite muodossa peppi@mail.com.'
    },
    phoneNumber: {
      valueMissing: 'Puhelinnumero on pakollinen',
      patternMismatch: 'Puhelinnumero on virheellinen'
    },
    websiteUrlField: {
      patternMismatch: 'Verkkokaupan URL-osoite on virheellinen'
    }
  },
  countries: {
    FI: 'Suomi',
    SE: 'Ruotsi',
    PL: 'Puola',
    EE: 'Viro',
    GB: 'Englanti',
    CZ: 'Tsekin tasavalta',
    HU: 'Unkari',
    DK: 'Tanska',
    DE: 'Saksa',
    ES: 'Espanja',
    BE: 'Belgia',
    NO: 'Norja',
    LT: 'Liettua',
    CH: 'Sveitsi',
    IE: 'Irlanti',
    NL: 'Alankomaat',
    SK: 'Slovakia',
    FR: 'Ranska',
    RO: 'Romania',
    LV: 'Latvia',
    BG: 'Bulgaria',
    PT: 'Portugali',
    GI: 'Gibraltar',
    RU: 'Venäjä',
    AT: 'Itävalta',
    IT: 'Italia',
    RS: 'Serbia'
  },
  becomeCustomerWizard: {
    0: 'Rekisteröidy yritysasiakkaaksi',
    1: 'Vahvista rekisteröitymisen tiedot',
    2: 'Yritysasiakkaan hinnasto',
    3: 'Lue ja allekirjoita sopimus',
    4: 'Vahvista sähköpostiosoite',
    5: 'Ready',
    6: 'Thank you'
  },
  contractView: {
    buttonBack: 'Takaisin',
    buttonAccept: 'Allekirjoita sopimus',
    buttonDownload: 'Lataa PDF:nä',
    header: 'Tutustu sopimukseen ennen sen allekirjoittamista.',
    contractHeader: 'Posti Oy:n sopimusasiakkaiden sähköisten kanavien käyttöehdot 1.1.2023',
    subtitle1: '1. Soveltamisala',
    subtitle2: '2. Sopimusehdot',
    subtitle3: '3. Palvelun käyttöönotto',
    subtitle4: '4. Palvelu',
    subtitle5: '5. Maksutavat',
    subtitle6: '6. Tunnukset ja pääkäyttäjä',
    subtitle7: '7. Asiakkaan vastuu',
    subtitle8: '8. Asiakkaan integraatiorajapinnat Palveluun',
    subtitle9: '9. Postin vastuu',
    subtitle10: '10. Palvelun ja Käyttöehtojen muuttaminen ja lopettaminen',
    subtitle11: '11. Käyttöehtojen voimassaolo',
    subtitle12: '12. Muut ehdot',
    content1: '1.1. Posti Oy (y-tunnus: 2344200-4), Posti Jakelu Oy:n (y-tunnus: 0109357-9) ja niiden kanssa samaan konserniin kuuluvat yhtiöt (jäljempänä ”Posti”) tarjoavat sopimusasiakkailleen (Asiakas) digitaalisia kanavia ja palveluita Postin sopimuspalveluiden käyttämiseen ja Asiakkaan omien tietojen hallinnointiin (Palvelu). Näitä käyttöehtoja (Käyttöehdot) sovelletaan Asiakkaan käyttämään Palveluun.',
    content2: '2.1. Palvelun käyttöön sovelletaan ensisijaisesti näitä Käyttöehtoja ja toissijaisesti Postin yleisiä sopimusehtoja. Lisäksi Posti voi antaa erikseen Palvelussa sen käyttöön liittyviä ohjeita.',
    content22: '2.2. Mikäli mahdolliset Postin Palvelussa antamat ohjeet ovat ristiriidassa Käyttöehtojen kanssa, sovelletaan ensisijaisesti kyseisiä ohjeita.',
    content23: '2.3.  Palvelun kautta saataviin Postin palveluihin sovelletaan mm. palvelun tuote-ehtoja ja Postin yleisiä sopimusehtoja yritysasiakkaille sekä muita mahdollisia asiakirjoja siten kuin yleisissä sopimusehdoissa kohdassa 20 todetaan.',
    content3: '3.1. Palvelun käyttöönotto edellyttää, että Asiakas on sopinut Postin kanssa sopimusasiakkuudesta, joka oikeuttaa Postin sopimuspalveluiden käyttöön.',
    content32: '3.2. Asiakas hyväksyy Palvelun Käyttöehdot ryhtyessään käyttämään Palvelua.',
    content4: '4.1. Asiakkaan on mahdollista saada Palvelussa raportteja käyttämistään Postin palveluista sekä tilata tai käyttää Palvelussa tarjolla olevia palveluita.',
    content42: '4.2. Palvelun käyttö tapahtuu Asiakkaan vastuulla. Posti ei vastaa siitä, että Palvelu on keskeytyksettä ja virheettä Asiakkaan käytettävissä.',
    content43: '4.3. Osa palveluista voi edellyttää Asiakkaan tai sen puolesta toimivan tunnistamista.',
    content44: '4.4. Asiakas saa käyttää Palvelua vain omassa sisäisessä toiminnassaan. Asiakkaalla ei ole oikeutta jälleenmyydä tai jaella Palvelua muulla vastaavalla tavalla.',
    content45: '4.5. Palvelun käyttö voi mahdollistaa pääsyn käyttämään tai tilaamaan myös kolmansien osapuolten ("Muu palveluntarjoaja") tuotteita ja palveluita. Posti ei vastaa näiden Muiden palveluntarjoajien tuotteista, palveluista, toiminnasta taikka antamista tiedoista. Sopimus Muun palveluntarjoajan tuotteista ja palveluista tehdään Asiakkaan ja Muun palveluntarjoajan välillä, eikä Posti ole kyseisen sopimuksen osapuoli eikä vastaa siihen liittyvistä velvoitteista.',
    content5: '5.1. Ellei asiakkaan kanssa ole sovittu laskutuksesta (itsepalveluasiakas), palvelut on maksettava Palvelussa kulloinkin tarjolla olevilla maksutavoilla tilaushetkellä. Postilla on oikeus veloittaa asiakkaan maksut tai asettaa katevaraus asiakkaan käytössä olevalle maksutavalle.',
    content52: '5.2. Asiointitili on Posti Oy:n itsepalveluasiakkailleen tarjoama maksutapa. Asiointitililtä voi maksaa Postin Palvelussa tarjoamia palveluita.',
    content53: '5.3. Itsepalveluasiakas vastaa siitä, että Asiointitilillä tai muulla asiakkaan käyttämällä ja Postin hyväksymällä maksutavalla on aina riittävät varat asiakkaan ostojen maksamiseksi. Asiointitilin saldoa ei voi ylittää.',
    content54: '5.4. Asiakkaan siirtäessä varoja pankkitililtään Asiointitilille tilisiirrossa on käytettävä Postin antamaa viitenumeroa, jotta varat voidaan kohdentaa Asiakkaan Asiointitilille. Asiakkaan omassa pankissaan tekemällä tilisiirrolla siirretyt varat ovat käytettävissä Asiointitilillä pääsääntöisesti tilisiirron toteutumista seuraavana pankkipäivänä. Tilisiirron toteutumiseen kuluva aikaa riippuu pankkien välisistä sopimuksista. Asiakas on tietoinen siitä, että tilisiirron kirjautumiseen Asiointitilille voi kulua useita päiviä. Asiakkaan siirtäessä varoja pankkitililtään Asiointitilille Asiointitilin verkkomaksupainikkeella, varat ovat Asiakkaan käytettävissä Asiointitilillä välittömästi onnistuneen verkkomaksun jälkeen.',
    content55: '5.5. Posti ei maksa korkoa Asiointitilin varoille. Asiakkaiden varat on erotettu kirjanpidossa Postin varoista.',
    content56: '5.6. Asiakas voi koska tahansa nostaa Asiointitilillä olevat käytettävissään olevat varat. Varat maksetaan Asiakkaan Asiointitilin vastatiliksi ilmoittamalle pankkitilille. Asiakkaan käytettävissä olevien varojen lisäksi Asiointitilillä voi olla katevarauksin Asiakkaan palveluihin varattuja varoja tai Postin kampanjaluonteisesti myöntämiä tai hyvityksenä maksamia maksuyksikköjä, joita voi käyttää ainoastaan Postin palveluiden maksamiseen Verkosto-palvelussa ja joita ei voi nostaa Asiointitililtä.',
    content6: '6.1. Asiakkaan on ilmoitettava Postille vähintään yksi Pääkäyttäjä. Posti toimittaa Asiakkaalle Pääkäyttäjän tunnukset hyväksyttyään Asiakkaan rekisteröitymisen.',
    content62: '6.2. Asiakkaan Pääkäyttäjä luo ja ylläpitää Asiakkaan muiden käyttäjien tunnuksia ja toimii Postin ja Asiakkaan välisenä yhteyshenkilönä Palveluun liittyvissä asioissa. Asiakas vastaa siitä, että asiointiportaalissa on aina ajantasaiset tiedot Asiakkaan pääkäyttäjästä.',
    content63: '6.3. Pääkäyttäjällä on oikeus käyttää kaikkia Palvelun osia ja aineistoja, joihin Asiakkaalla on oikeus. Muut kuin Pääkäyttäjän tunnukset voivat olla käyttöoikeuksiltaan rajoitettuja.',
    content64: '6.4. Asiakkaan on säilytettävä kaikkia tunnuksiaan huolellisesti ja siten, että ne eivät joudu oikeudetta sivullisten käyttöön. Mikäli Asiakkaan tunnus joutuu sivullisen haltuun tai katoaa, Asiakkaan on itse suljettava tunnuksen käyttöoikeudet.',
    content65: '6.5. Tunnukset ovat henkilökohtaisia ja henkilö voi käyttää Palvelua samalla tunnuksella eri asiakkaiden edustajana. Asiakas vastaa siitä, että tunnuksiin liitetty Asiakkaan edustusoikeus on vain sellaisten henkilöiden käytössä, joilla on oikeus toimia Asiakkaan edustajana Palvelussa edustusoikeuden mahdollistamalla tavalla. Asiakkaan on suljettava tunnuksiin liitetyt edustusoikeudet itse.',
    content66: '6.6. Asiakkaan tunnusten käyttö vastaa Asiakkaan allekirjoitusta.',
    content67: '6.7. Postilla on oikeus, mutta ei velvollisuutta poistaa Asiakkaan tunnukset, jotka ovat olleet käyttämättä vähintään vuoden.',
    content7: '7.1. Asiakas vastaa tunnusten ja niihin liittyvien käyttöoikeuksien hallinnoinnista. Asiakkaan Pääkäyttäjä hallinnoi Asiakkaan tunnuksia ja niihin liitettyjen käyttöoikeuksien laajuutta.',
    content72: '7.2. Asiakaan Pääkäyttäjä vastaa käyttäjätuen antamisesta Asiakkaan muille käyttäjille.',
    content73: '7.3. Asiakas vastaa kaikista Palvelussa tunnuksillaan tehdyistä toimista.',
    content74: '7.4. Asiakas vastaa siitä, että hänen käyttämänsä tietokone- ja muut laitteistot, ohjelmistot ja tietoliikenneyhteydet ovat Postin ilmoittamia vaatimuksia vastaavat ja riittävästi suojatut muun muassa käyttämällä ajantasaisia ja tehokkaita viruksen torjuntaohjelmia ja muista vastaavia suojauskeinoja. Asiakas vastaa itse Palvelun käytön edellyttämien laitteiden, ohjelmistojen ja viestintä- sekä tietoliikenneyhteyksien kustannuksista ja toiminnasta. Asiakas on velvollinen noudattamaan Palvelun käytössä Postin kulloinkin voimassa olevia käyttö-, turvallisuus- ja muita ohjeita.',
    content75: '7.5. Asiakkaan on annettava Palvelua varten tarvittavat tiedot Postille. Asiakkaan on viipymättä ilmoitettava tietojen muutoksista. Asiakas vastaa Palveluun antamiensa tietojen  oikeellisuudesta. Posti ei ole velvollinen tarkistamaan tai korjaamaan tietoja, jotka Asiakas antaa Palvelun yhteydessä.',
    content76: '7.6. Asiakas vastaa Palvelussa säilyttämistään, käsittelemistään tai välittämistään tiedoista, tiedostoista, dokumenteista ja muusta aineistoista (Aineisto).',
    content77: '7.7. Asiakas vastaa Palvelun lakien ja hyvän tavan mukaisesta käytöstä. Posti ei valvo Palvelua hyväksikäyttäen tuotettua, lähetettyä, välitettyä tai muuten käsiteltyä Aineistoa eikä Posti vastaa tällaisen aineiston lainvastaisesta tai loukkaavasta sisällöstä. Asiakas vastaa siitä, ettei hän Palvelua käyttäen tuota, lähetä, säilytä tai muutoin käsittele aineistoa, joka loukkaa tekijän tai muita oikeuksia, hyvää tapaa, lakia tai viranomaisten määräyksiä. Loukkaaminen voi aiheuttaa velvollisuuden korvata siitä aiheutunut vahinko. Asiakas vastaa Palvelun avulla tai kautta lähettämänsä tai muuten käsittelemänsä Aineiston sisällöstä ja esitysmuodosta.',
    content78: '7.8. Asiakkaan tulee ennen Aineiston lisäämistä Palveluun varmistaa, ettei Aineisto ole Palvelua koskevien sopimusehtojen taikka Postin antamien ohjeiden vastainen ja ettei Aineisto sisällä tietokoneviruksia tai muita sellaisia ominaisuuksia, jotka voivat aiheuttaa vahinkoa. Asiakas vastaa siitä, ettei tuo Palveluun Aineistoa, joka sisältää viruksia tai muita haitallisia ominaisuuksia sekä ettei levitä tai muuten haittaa aiheuttavalla tavalla käsittele tällaista Aineistoa.',
    content79: '7.9. Asiakkaan käsitellessä asiakas- tai muita henkilötietojaan Palvelussa, Asiakas vastaa henkilötietojen käsittelyä koskevan lainsäädännön noudattamisesta rekisterinpitäjänä. Asiakkaan on varmistuttava oikeudestaan kyseisten henkilötietojen käsittelyyn sekä noudatettava muutoinkin henkilötietojen käsittelyssä laissa säädettyjä tai viranomaisten edellyttämiä velvoitteita.',
    content8: '8.1. Asiakkaan kanssa voidaan sopia integraatiorajapinnasta Palveluun. Edellä sanotusta poiketen Asiakas ei voi itse hallinnoida integraatiotunnuksia.',
    content9: '9.1. Posti vastaa hyvän tietojenkäsittelytavan mukaisesta Palvelun toteutuksesta.',
    content92: '9.2. Posti ylläpitää Palvelua niin, että se on pääsääntöisesti käytettävissä 24 h vuorokaudessa viikon kaikkina päivinä. Posti voi tilapäisesti keskeyttää Palvelun esim. muutos- tai korjaustöiden tai järjestelmän ylläpidon, huollon tai korjausten aiheuttamien toimenpiteiden johdosta.',
    content93: '9.3. Posti on vahingonkorvausvelvollinen Postin vastuupiiriin kuuluvista, Postin tuottamuksesta Asiakkaalle aiheutuneista välittömistä ja Asiakkaan osoittamista vahingoista Postin yleisten sopimusehtojen mukaisesti.',
    content94: '9.4. Edellä sanotusta huolimatta Posti ei vastaa Asiakkaan Aineiston tuhoutumisen, katoamisen tai muuttumisen aiheuttamista vahingoista.',
    content10: '10.1. Asiakas on tietoinen siitä, että Posti kehittää Palvelua jatkuvasti, mistä johtuen Palvelu ja sen sisältö voivat muuttua koska tahansa. Käyttöehtojen muutoksesta ilmoitetaan Palvelussa.',
    content102: '10.2. Postilla on oikeus lopettaa Palvelun tuottaminen osittain tai kokonaan. Tästä muutoksesta ilmoitetaan Palvelussa hyvissä ajoin erikseen.',
    content103: '10.3. Postilla on oikeus perustellusta syystä, kuten esimerkiksi Asiakkaan Käyttöehtojen vastaisen toiminnan tai muun sopimusrikkomuksen takia sulkea Asiakkaan käyttöoikeus ennalta ilmoittamatta.',
    content104: '10.4. Postilla on oikeus Asiakasta kuulematta keskeyttää Palvelu ja poistaa Aineisto, jonka väitetään loukkaavan toisen oikeutta tai oleva lain, viranomaisohjeiden tai hyvän tavan vastainen.',
    content11: '11.1. Käyttöehdot tulevat voimaan 1.1.2023 ja ovat voimassa toistaiseksi. ',
    content112: '11.2. Posti voi irtisanoa Käyttöehdot päättymään ilmoittamalla siitä 30 päivää ennalta Palvelussa. Asiakkaan käyttöoikeus päättyy Asiakkaan sopimusasiakkuuden päättyessä.',
    content12: '12.1. Mikäli Asiakkaan Aineisto on Käyttöehtojen tai muun Asiakkaan ja Postin välisen sopimuksen vastaista, Posti ei ole velvollinen toteuttamaan Palvelua tai palveluja mainittujen sopimusten mukaisesti. Mikäli ilmenee, että Asiakkaan Palvelun avulla käsittelemä Aineisto on sisällöltään loukkaavaa, loukkaa toisen tekijän- tai muita oikeuksia, on lain tai Käyttöehtojen tai Asiakkaan muiden sopimusten ehtojen vastainen taikka voi aiheuttaa vahinkoa, on Postilla oikeus poistaa kyseinen aineisto Asiakasta kuulematta.',
    content122: '12.2. Kaikki oikeudet (omistusoikeus, tekijänoikeus ja muut immateriaalioikeudet) Palveluun ja niihin liittyvään Postin tarjoamaan aineistoon ovat Postilla tai sen lisenssinantajilla. Oikeudet Muiden palveluntarjoajien tuotteisiin ja palveluihin sekä niihin liittyvään aineistoon ovat kyseisillä palveluntarjoajilla tai niiden lisenssinantajilla. Asiakkaalla ei ole mitään immateriaalioikeuksia Palveluun tai siihen liittyviin ohjelmistoihin tai aineistoihin tai vastaavaan.',
    content123: '12.3. Jos tämän sopimuksen mukaisessa Palvelussa käsitellään Asiakkaan omistamia henkilötietoja EU:n ulkopuolella esimerkiksi tietojärjestelmäsyistä, Posti voi siirtää henkilötietoja lainsäädännön mukaisin keinoin Palvelun suorittamiseksi.',
    bottomPart1: 'Posti tarjoaa sähköisen tilauskanavan Postin sopimuspalveluiden käyttämiseen ja omien tietojesi hallinnointiin (”Palvelu”). Käyttääksesi Palvelua yrityksesi tulee olla Postin sopimusasiakas ja sillä tulee olla asiointitunnukset. Palveluun sovelletaan Posti Oy:n Sopimusasiakkaiden Sähköisten kanavien käyttöehtoja. Hyväksyt yllä olevat käyttöehdot ja käyttöehtojen liitteenä olevat Henkilötietojen käsittelysopimukset',
    bottomPart1_5: 'ryhtyessäsi käyttämään Palveluita.',
    bottomPart2: 'Palvelussa saatavilla oleviin Postin sopimuspalveluihin sovelletaan kulloinkin voimassa olevia tuote-ehtoja, Postin yleisiä sopimusehtoja ja hinnastoja. Hyväksyt Postin palveluun sovellettavat ehdot tilatessasi palvelun. Tutustukaa huolellisesti ',
    bottomPart3: 'sopimus- ja tuote-ehtoihin',
    bottomPart4: 'ja ',
    bottomPart5: 'hinnastoihin',
    bottomPart6: ' ennen palveluiden käyttöönottoa. Kaikki Postin palvelut eivät ole saatavilla ilman erillistä asiakastunnusta.',
    creditLimit: 'Laskutuksen luottoraja on 2000 euroa.',
    enterName: 'Allekirjoita sopimus kirjoittamalla pääkäyttäjän koko nimi:',
    errorDefault: 'Kirjoita pääkäyttäjän koko nimi.',
    errorSecondary: 'Nimessä on virhe.',
    dialogHeader: 'Allekirjoita sopimus',
    buttonSign: 'Allekirjoita',
    buttonCancel: 'Peruuta',
    fullName: 'Koko nimi',
    logoutDialogHeader: 'Haluatko varmasti keskeyttää rekisteröitymisen?',
    buttonLeaveAndLogout: 'Kirjaudu ulos ja sulje',
    buttonReturnToContract: 'Palaa sopimukseen',
    logoutDialogText: 'Jos jätät rekisteröitymisen nyt kesken, kaikki tietosi poistuvat. Voit rekisteröityä asiakkaaksi myöhemmin, mutta joudut syöttämään tiedot uudestaan.'
  },
  errorView: {
    header: 'Tilin luominen meni pieleen',
    body: 'Valitettavasti yritystilin luominen ei onnistunut.',
    text: 'Täytä ',
    link: 'tämä lomake',
    text2: ', niin voimme luoda tilin yrityksellesi.',
    button: 'Palaa Postin etusivulle'
  },
  errorViewNotAcceptable: {
    header: 'Tilin luominen ei onnistunut!',
    body: '<0>Valitettavasti yritystilin luominen ei onnistunut luottotietotarkastuksesta johtuen. Voit käyttää lähettämisen palveluitamme ilman sopimusta</0> <1>täältä</1>.'
  },
  errorViewConflict: {
    header: 'Tilin luominen ei onnistunut!',
    body: '<0>Valitettavasti yritystilin luominen ei onnistunut, koska yrityksellä on jo asiakkuus Postin kanssa. Mikäli haluat ottaa uusia palveluita käyttöön, voit jättää yhteydenottopyynnön</0> <1>lomakkeella</1>.'
  },
  errorViewContractExists: {
    header: 'Sopimus on jo olemassa',
    subheader: '<0>Yritykseltä {{companyName}}, {{businessId}} löytyy jo logistiikan sopimus.</0>',
    howToProceed: 'Miten jatkan?',
    newAccountHeader: 'Haluan luoda uuden käyttäjätilin',
    newAccountBody: '<0>Uuden käyttäjätilin luomiseksi sinun tulee ottaa yhteyttä yrityksesi henkilöön, jolla on oikeudet käyttäjähallintaan, jotta hän voi kutsua sinut</0> <1>OmaPosti Pron Käyttäjähallinnasta</1>',
    accessRightsHeader: 'Tarvitsen käyttöoikeudet lähetysten lähettämiseen/luomiseen',
    accessRightsBody: '<0>Kirjaudu</0> <1>Oma Posti Pro -palveluun</1> <0>ja siirry lähetysosioon. Jos sinulla ei ole oikeuksia lähetysosioon, saat sieltä ohjeet niiden hankkimiseen.</0>',
    didntKnowHeader: 'En tiennyt, että meillä on jo logistiikan sopimus',
    didntKnowBody: '<0>Ei hätää. Voimassa oleva sopimus säilyy ennallaan ja on edelleen käytössä. Voit jatkaa normaalisti.</0><br/><br/><0>Jos pakettituotteita ei näy lähetyksissä, sinulla ei todennäköisesti ole käyttöoikeuksia.</0><br/><0>Käyttöoikeuksien saamiseksi sinun tulee ottaa yhteyttä yrityksesi henkilöön, joka hoitaa käyttäjähallintaa OmaPosti Prossa.</0>',
    didntKnowLink: '<0>Lue lisää käyttäjähallinnasta asiakaspalveluportaalistamme</0>',
    negotiateHeader: 'Haluan neuvotella nykyisen sopimuksen uudelleen',
    negotiateLink: '<0>Täytä tämä lomake, niin saat yhteyden myyntiin</0>',
    talkHeader: 'Haluan puhua jonkun kanssa',
    talkLink: '<0>Täytä tämä lomake, niin saat yhteyden myyntiin</0>'
  },
  pricingView: {
    small: 'Pikkupaketti',
    normal: 'Postipaketti',
    homeParcelCity: 'Kotipaketti',
    homeParcelCityDetails: '(Kaupunkialueet)',
    homeParcelOther: 'Kotipaketti',
    homeParcelOtherDetails: '(Muut alueet)',
    expressParcel: 'Express-paketti',
    customerReturn: 'Asiakaspalautus',
    dialog: {
      header: 'Kotiinkuljetusten alueellinen hinnoittelu',
      contentHeader: 'Kaupunkialueet',
      contentBody: 'Kaupunkialueiden postinumerot kattavat Suomen suurimpien kaupunkien keskustat (lista alla). Alueilla asuu noin 40 % Suomen väestöstä.',
      subHeader: 'Muut alueet',
      subBody: 'Muilla alueilla tarkoitetaan muita kuin suurien kaupunkien keskustoja, esimerkiksi lähiöitä ja maaseutua.',
      postcodeStart: 'Lataa  ',
      postcodeLink: 'Kotipakettien',
      postcodeEnd: ' postinumerolista Kotipaketin esittelysivulta.',
      button: 'Sulje'
    },
    area: {
      helsinki: 'Pääkaupunkiseutu',
      turku: 'Turun seutu',
      tampere: 'Tampereen seutu',
      oulu: 'Oulu',
      jyväskylä: 'Jyväskylä',
      lahti: 'Lahti',
      kuopio: 'Kuopio'
    },
    header: 'Lähetä paketit matkaan edullisemmin',
    subtitle: 'Hinnat',
    service: 'Paketit',
    pricesFrom: 'Hinta alkaen',
    fuelPart1: 'Pakettien hintaan lisätään ',
    fuelLink: 'polttoainelisä',
    fuelPart2: '. Minimiveloituspaino on 1 kg.',
    buttonDownload: 'Lataa hinnasto',
    payFor: 'Mistä hinta muodostuu?',
    titleOne: 'Lähetys',
    titleTwo: 'Polttoaine',
    titleThree: 'Lisäpalvelut',
    titleFour: 'Laskutus',
    titleFive: 'Kahvakuulan <br/> lähettäminen',
    titleSix: 'Tyynyn <br/> lähettäminen',
    titleSeven: 'Arvonlisävero (ALV)',
    serviceOne: 'Lähetyksen hinta määräytyy paketin todellisen painon tai tilavuuspainon mukaan (katso esimerkki alla).',
    serviceTwo: 'Hintoihin lisätään polttoainelisä.',
    serviceThree: 'Lähetyksen hinta muuttuu, jos tilaat sille lisäpalveluja (esimerkiksi Postiennakko).',
    serviceFour: 'Jos lähetät viikossa paketteja yli 100 € lähetämme laskun viikoittain, alle 100 € laskut laskutamme kuukausittain. Jos laskutettava summa on alle 100 € kuukaudessa perimme 10 € laskutuslisän. Paperilaskun hinta on 5,24 € + alv/lasku.',
    serviceFive: 'Jos lähetät kahvakuulan 43 x 35 x 18 cm paketissa, on paketin todellinen paino (16 kg) suurempi kuin sen tilavuuspaino (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Tällöin hinta Postipaketille lasketaan todellisen painon 16 kg mukaan, esimerkiksi (5,00 € + (0,20 €/kg x 16kg)) x 1,12 = 9,14 € (Hinta sisältää 12 % polttoainelisän, mutta ei muita lisiä eikä ALV).',
    serviceSix: 'Jos lähetät tyynyn 43 x 35 x 18 cm paketissa, on paketin todellinen paino (alle 1 kg) pienempi kuin sen tilavuuspaino (0,43 m x 0,25 m x 0,18 m x 250 kg/m3 = 6,77 kg). Tällöin hinta Postipaketille lasketaan tilavuuspainon 6,77 kg mukaan, esimerkiksi (5,00 € + (0,20 €/kg x 6,77kg)) x 1,12 = 7,11 € (Hinta sisältää 12 % polttoainelisän, mutta ei muita lisiä eikä ALV).',
    serviceSeven: 'Hintoihin lisätään laskutuksessa kulloinkin voimassaoleva arvonlisävero. Hintoihin ei lisätä arvonlisäveroa Ahvenanmaalle, Euroopan yhteisön arvonlisäveroalueen ulkopuolisille erityisalueille sekä muihin kuin Euroopan unionin jäsenmaihin osoitettuihin postilähetyksiin.',
    weightVolume: 'Lähetyksen hinta: todellinen paino vai tilavuuspaino?',
    weightVolumeSubtitleStart: 'Lähetyksen hinta määräytyy paketin todellisen painon tai tilavuuspainon (leveys x pituus x korkeus x 250 kg/m3 **) perusteella sen mukaan, kumpi painoista on suurempi. Vähimmäispaino on 100 g. Esimerkiksi:',
    standardStart: '** 250 kg/m',
    standardSup: '3',
    standardEnd: 'on logistiikkayritysten käyttämä tiheyden standardimitta tilavuuspainon laskemiseksi.',
    buttonCancel: 'Takaisin',
    buttonNext: 'Jatka',
    deliveryZone: '*Express-paketteihin lisätään kohdepostinumeron mukainen jakeluvyöhykelisämaksu: vyöhykkeelle 1: 0,5 € /lähetys ja vyöhykkeelle 2: 1 € /lähetys. Katso postinumeroiden jakeluvyöhykkeet',
    deliveryZoneLink: 'täältä',
    pricesValid: 'Hinnat voimassa toistaiseksi',
    otherServicePrices: 'Yllä mainitut kotimaan palvelut tarjoaa Posti Oy. Listatut kansainväliset palvelut tarjoaa Posti Oy tai Posti Jakelu Oy. Muihin palveluihin, joita tarjoaa Posti Oy tai Posti Jakelu Oy sovelletaan hinnastohintoja.',
    invoicingDetails: 'Laskutustiedot nykyisille laskutusasiakkaille säilyvät ennallaan',
    internationalParcels: 'Kansainväliset pakettituotteet',
    seePricesListBelow: 'Katso alla oleva lista hinnoittelusta'
  },
  //Includes only the translations that differ from the default pricing view
  ecommercePricingView: {
    pricesValid: '<strong>Hinnat taso 1</strong> (voimassa toistaiseksi) alle 500 pakettia kalenterivuodessa',
    pricesValidTwo: '<strong>Hinnat taso 2</strong> (voimassa toistaiseksi) yli 500 pakettia kalenterivuodessa',
    cardKettlebell: 'Jos lähetät kahvakuulan <strong>43 x 35 x 18 cm paketissa</strong>, on paketin todellinen paino (16 kg) suurempi kuin sen tilavuuspaino (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Tällöin Postipaketin hinta lasketaan <strong>todellisen painon 16 kg mukaan</strong>, esimerkiksi 5,90€ + (0, 30 €/kg x 16 kg) = <strong>10,70 €</strong> (ei sisällä muita lisiä tai ALV).',
    cardPillow: 'Jos lähetät tyynyn <strong>43 x 35 x 18 cm paketissa</strong>, on paketin todellinen paino (alle 1 kg) pienempi kuin sen tilavuuspaino (0,43 m x 0,35 m x 0,18 m x 250 kg/m3 = 6,77 kg). Tällöin hinta Postipaketille lasketaan <strong>tilavuuspainon 6,77 kg mukaan</strong>, esimerkiksi 5,90€ + (0,30€/kg x 6,77kg) = <strong>7,93 €</strong> (ei sisällä muita lisiä tai ALV).',
    serviceHeader: 'Hyödyllistä tietoa liittyen hinnoitteluun',
    titleNoFees: 'Ei ylimääräisiä lisiä',
    bodyNoFees: 'Ei pienlaskutuslisää tai erillistä polttoainelisää Starttipaketin tuotteista.',
    serviceFour: 'Jos lähetät viikossa paketteja yli 100 € lähetämme laskun viikoittain, alle 100 € laskut laskutamme kuukausittain. Paperilaskun hinta on 5,24 € + alv/lasku.',
    info: '* Lähetyksen hinta määräytyy paketin rahdituspainon eli todellisen painon tai tilavuuspainon (leveys x pituus x korkeus x 250kg/m3 *) perusteella sen mukaan, kumpi painoista on suurempi. Vähimmäispaino on 100 g ja enimmäispaino on 25 kg.',
    infoLink: 'Lue lisää rahdituspainosta.',
    infoSecond: '** Express-paketteihin lisätään kohdepostinumeron mukainen jakeluvyöhykelisämaksu: vyöhykkeelle 1: 0,6 € /lähetys ja vyöhykkeelle 2: 1,2  € /lähetys. Katso postinumeroiden jakeluvyöhykkeet',
    infoSecondLink: 'täältä',
    readMore: 'Lue lisää Tason 2 hinnoista hintaliitteestä',
    infoThird: 'Yllä manitut palvelut tarjoaa Posti Oy. Muihin palveluihin, joita voita tarjota Posti Oy tai Posti Jakelu Oy sovelletaan',
    infoThirdLink: 'hinnastohintoja',
    normalParcelSmall: 'Postipaketti (max. 40 x 32 x 26 cm)',
    normalParcelLarge: 'Postipaketti (yli 40 x 32 x 26 cm)*',
    homeParcelCity: 'Kotipaketti (max. 40 x 32 x 26 cm)',
    homeParcelOther: 'Kotipaketti (yli 40 x 32 x 26 cm)*',
    deliveryHome: 'Koti',
    deliveryPickup: 'Noutopiste',
    deliveryBusiness: 'Yritys',
    expressParcel: '**Express paketti (max. 40 x 32 x 26 cm)',
    expressParcelLarge: '**Express paketti (yli 40 x 32 x 26 cm)*',
    returnSmall: 'Palautus (max. 40 x 32 x 26 cm)',
    returnLarge: 'Palautus (yli 40 x 32 x 26 cm)*',
    modalHeader: 'Miten rahdituspaino <br/> lasketaan?',
    modalInfo: 'Rahdituspaino tarkoittaa, että lähetyksen hinta määräytyy paketin todellisen painon tai tilavuuspainon (leveys x pituus x korkeus x 250kg/m3 *) perusteella sen mukaan, kumpi painoista on suurempi. Vähimmäispaino on 100 g ja enimmäispaino on 25 kg. Rahdituspaino vaikuttaa Kotipaketin sekä yli 40 x 32 x 26 cm kokoisen Postipaketin ja palautuksen hintoihin.',
    tableHeaders: {
      shipmentPrices: 'Lähetysten hinnat',
      item: '€ / Lähetys',
      kg: '€ / kg*',
      delivery: 'Toimitus',
      customerReturn: 'Asiakaspalautus tai noutamaton lähetys'
    },
    campaignTitle: 'Kesäkampanjan jälkeiset hinnat pienille verkkokaupoille***',
    campaignInfo: '*** Hintakampanja voimassa 16.5.2022-31.8.2022. Pikkupaketti 4,00 €. Postipaketti 5,50 € ja Palautus 6,50 €. (Hinta on kiinteä hinta, kun paketin koko on enintään 40 x 32 x 26 cm. Muussa tapauksessa tarjoushinta koskee Postipaketin ja Palautuksen kiinteää osaa.)',
    currentPrices: 'Nykyinen hinnasto',
    upcomingPrices: 'Päivitämme Starttipaketin hinnastoa 26.2.2023. Lataa alta uusi hinnasto.'
  },
  footer: {
    help: 'Kuinka voimme auttaa?',
    support: 'Ota yhteyttä asiakaspalveluun'
  },
  header: {
    language: 'Suomi',
    logOut: 'Kirjaudu ulos'
  },
  emailVerificationView: {
    logOutButton: 'Kirjaudu ulos',
    header: 'Melkein valmista! Vahvista vielä sähköpostiosoitteesi',
    subtitle: 'Lähetimme vahvistusviestin osoitteeseen:',
    bodyText: 'Viimeistele yritystilin luominen klikkaamalla vahvistusviestissä olevaa linkkiä. Linkki on voimassa 7 päivää. Viestin saapuminen voi kestää muutamia minuutteja.',
    requestText: 'Tarkista roskapostikansio. Jos viestiä ei näy, ',
    sendLink: 'tilaa uusi vahvistusviesti.',
    customerServiceText: 'Etkö saanut linkkiä? Annoitko vahingossa väärän sähköpostiosoitteen?',
    customerServiceLink: 'Ota yhteys asiakaspalveluun.',
    cashCustomerNotification1: 'Vaikuttaa siltä, että sinulla on jo olemassaoleva yritysasiakkuus Postin kanssa! Päivitämme nyt vanhat asiakastietosi, sopimusehdot ja hinnat uusiin.',
    cashCustomerNotification2: 'Saat pian vahvistuslinkin sähköpostiisi. Klikattuasi linkkiä, voit kirjautua sisään Oma Posti Pro:hon ja aloittaa lähetysten luonnin!',
    invoicingCustomerNotification1: 'Huomasimme, että yrityksesi on jo Postin asiakas! Olemme nyt luoneet sinulle uuden logistiikan sopimuksen ja yhdistäneet sen olemassa olevaan tiliisi. Voit katsoa ja muokata yrityksesi laskutustietoja OmaPosti Prossa.',
    invoicingCustomerNotification2: 'Vahvista sähköpostiosoitteesi, niin pääset kirjautumaan OmaPosti Prohon ja aloittamaan lähettämisen heti!',
    invoicingCustomerNotification3: 'Saatamme ottaa sinuun yhteyttä nykyisen sopimuksesi ja uuden sopimuksen yhdistämisestä.'
  },
  maintenanceView: {
    header: 'Rekisteröityminen ei tällä hetkellä onnistu',
    body: 'Teemme huoltotöitä nettisivuillamme, joten uusien yritysasiakkaiden rekisteröiminen ei onnistu tällä hetkellä. Yritä uudestaan hetken kuluttua.',
    button: 'Palaa Postin etusivulle'
  },
  cash: {
    fourForty: '4,40 €',
    fourFifty: '4,50 €',
    fiveNinety: '5,90 €',
    fiveSeventy: '5,70 €',
    thirtyCents: '0,30 €',
    eightFifty: '8,50 €',
    eightNinety: '8,90 €',
    tenFifty: '10,50 €',
    tenNinety: '10,90 €',
    sixNinety: '6,90 €',
    sixSeventy: '6,70 €'
  }
};